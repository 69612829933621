import React, { useState } from 'react'
import { mobilVariant, loginKapakVariant, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import headerImg from '../../img/image.png'
import logo from '../../img/logo.png'
import { Input, Form, Button, Modal, ModalFooter } from 'reactstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { giris, sifremiunuttum } from '../../api/api'

const Login = () => {
    const [modal, setModal] = useState(false)
    const [forgetPass, setForgetPass] = useState(false)
    const [passOk, setPassOk] = useState(false)
    const [passMessage, setPassMessage] = useState('')
    const [message, setMessage] = useState('')
    const { handleSubmit } = useForm()

    const onSubmit = (values, e) => {
        const userName = window.btoa(e.target[0].value)
        const password = window.btoa(e.target[1].value)

        axios.get(`${giris}${userName},${password}`).then(res => {
            if (res.status === 200) {
                const value = res.data[0]
                if (value.durum === '0') {
                    setMessage(value.sonuc)
                    setModal(true)
                    localStorage.clear()
                } else {
                    localStorage.setItem('auth', value.durum)
                    localStorage.setItem('info', JSON.stringify(value))
                    window.location.href = '/'
                }
            }
        })
    }

    const onForgetPassword = (values, e) => {
        const mail = window.btoa(e.target[0].value)

        if (mail !== null && mail !== undefined && mail !== '') {
            axios.get(`${sifremiunuttum}${mail}`).then(res => {
                if (res.status === 200) {
                    const value = res.data[0]
                    setModal(true)
                    setPassOk(true)
                    setPassMessage(value.sonuc)
                }
            })
        }
    }

    return (
        <motion.div {...mobilVariant} className='login'>
            <motion.div {...loginKapakVariant} className='header-img'>
                <img src={headerImg} alt='' />
            </motion.div>

            <motion.div {...vitrinCategoriesVariant}>
                <div className='logo'>
                    <img src={logo} alt='' />
                </div>

                <div className='login-text'>
                    <span>Asil Otogaz üyesiyseniz lütfen giriş yapın</span>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='login-form'>
                        <div>
                            <Input type='input' placeholder='Email Adresi' />
                        </div>
                        <div className='mt-2'>
                            <div>
                                <div><Input type='password' placeholder='Şifreniz' /></div>
                                <div></div>
                            </div>
                        </div>
                        <div className='text-right mt-1'>
                            <small className='text-secondary' onClick={() => { setForgetPass(true); setModal(!modal) }}>Şifremi Unuttum</small>
                        </div>
                    </div>

                    <div className='align-items-center text-center'>
                        <Button className='login-btn'>GİRİŞ YAPIN</Button>
                        <div className='yada mt-2 text-secondary'><span>ya da</span></div>
                        <Button className='register-btn' onClick={() => window.location.href = '/register'}>ÜYE OLUN</Button>
                    </div>
                </Form>

                <div className='texts text-secondary'>
                    <div><a href='/contracts/55'>Üyelik Sözleşmesi</a></div>
                    <div><span>|</span></div>
                    <div><a href='/contracts/8'>Gizlilik Taahhütü</a></div>
                </div>
            </motion.div>

            <Modal isOpen={modal} toggle={() => { setModal(!modal); setForgetPass(!forgetPass); setMessage('') }} className='modal-dialog-centered'>
                {
                    !forgetPass ? <div>
                        <div className='header'>
                            <div>Hatalı Giriş</div>
                            <div className='close' onClick={() => setModal(!modal)}>x</div>
                        </div>
                        <div className='message'>{message}</div>
                        <div className='d-flex justify-content-end mb-2 mr-2' onClick={() => setModal(!modal)}><div className='ok'>Tamam</div></div>
                    </div> : null
                }

                {
                    forgetPass && !passOk ? <div>
                        <Form onSubmit={handleSubmit(onForgetPassword)}>

                            <div className='p-3'>
                                <div className='text-center'><b style={{ fontSize: 17 }}>Şifremi Unuttum</b></div>
                                <div className='text-center'><small>lütfen mail adresinizi yazınız!</small></div>
                                <div className='m-2'><Input type='input' /></div>
                            </div>
                            <ModalFooter className='text-primary d-flex p-2'>
                                <Button className='gonder bg-white text-primary border border-primary'>Gönder</Button>
                                <div className='iptal border border-primary' onClick={() => { setModal(!modal); setForgetPass(!forgetPass) }}>İptal</div>
                            </ModalFooter>
                        </Form>
                    </div> : null
                }

                {
                    passOk ? <div className='p-3'>
                        <div className='text-center'><b style={{ fontSize: 16 }}>Asil Otogaz</b></div>
                        <div className='p-2 text-secondary'>{passMessage}</div>
                        <div className='d-flex justify-content-center'>
                            <Button className='iptal border border-primary bg-white text-primary' onClick={() => { setModal(false); setPassOk(false); setForgetPass(false) }}>Tamam</Button>
                        </div>
                    </div> : null
                }
            </Modal>

        </motion.div >
    )
}

export default Login