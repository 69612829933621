import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { payInfoVariant } from '../../components/animation'
import axios from 'axios'
import { bankagetir, odemeTutarlari, odemeTutarlariHavale, sepetigetir, siparisTamamlaKKarti, taksitleriGetirKartla, siparisTamamlaHavale, odemeTutarlariCari, siparisTamamlaCari } from '../../api/api'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { Card, Form, Input, Button, Modal } from 'reactstrap'
import { encode, expriyFormat, turkish } from '../../components/general'

const PayInfo = () => {
    const [modal, setModal] = useState(false)
    const [basketLength, setBasketLength] = useState('0')
    const [selectPay, setSelectPay] = useState(1)
    const [number, SetNumber] = useState("")
    const [name, SetName] = useState("")
    const [date, SetDate] = useState("")
    const [isTaksit, setIsTaksit] = useState(false)
    const [cvc, SetCvc] = useState("")
    const [first, setFirst] = useState(false)
    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)
    const [fourth, setFourth] = useState(false)
    const [data, setData] = useState([])
    const [banks, setBanks] = useState([])
    const [taksitOk, setTaksitOk] = useState(false)
    const [havaleOk, setHavaleOk] = useState(false)
    const [cariOk, setCariOk] = useState(false)
    const [jokerVadaa, setJokerVada] = useState(false)
    const [selectbank, setSelectbank] = useState(0)
    const tesAdres = JSON.parse(localStorage.getItem('tesAdres'))
    const fatAdres = JSON.parse(localStorage.getItem('fatAdres'))
    const cargo = JSON.parse(localStorage.getItem('cargo'))
    const [selectedBank, setSelectedBank] = useState('0')
    const [indirimWitch, setIndirimWitch] = useState(0)
    const [selectedTaksit, setSelectedTaksit] = useState('0')
    const [selectedTaksitId, setSelectedTaksitId] = useState('0')
    const [dataTaksit, setDataTaksit] = useState([])
    const [notModal, setNotModal] = useState(false)
    const [notStokModal, setNotStokModal] = useState(false)
    const [havaleMessage, setHavaleMessage] = useState("")
    const [havaleModal, setHavaleModal] = useState(false)
    const [cariMessage, setCariMessage] = useState(false)
    const [cariModal, setCariModal] = useState(false)

    const getTaksit = () => {
        if (number.length < 20 && number.length > 15) {
            const newDataNumber = number.split(" ")
            axios.get(`${taksitleriGetirKartla}${newDataNumber[0]}${newDataNumber[1]}${newDataNumber[2]}${newDataNumber[3]}`).then(res => {
                if (res.data !== null && res.data !== undefined) {
                    const newList = []
                    newList.push({ Taksit_Baslik: "Peşin", Taksit_Id: 2, Taksit_KrediKarti: 1, Taksit_Oran: 0, Taksit_Sayi: 1 })
                    for (let i = 0; i < res.data.length; i++) {
                        newList.push(res.data[i])
                    }
                    setIndirimWitch(0)
                    setDataTaksit(newList)
                }
            })
        }
    }

    const getCariOdeme = () => {
        axios.get(`${odemeTutarlariCari}${window.btoa(tesAdres.value)},${window.btoa(cargo.value)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            setData(res.data[0])
        })
    }

    const getHavaleOdeme = () => {
        axios.get(`${odemeTutarlariHavale}${window.btoa(tesAdres.value)},${window.btoa(cargo.value)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            setData(res.data[0])
        })
    }

    const getData = () => {
        if (localStorage.getItem('tesAdres') !== undefined && localStorage.getItem('cargo') !== undefined) {
            axios.get(`${odemeTutarlari}${window.btoa(tesAdres.value)},${window.btoa(cargo.value)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                setData(res.data[0])
            })
        }

        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })

        axios.get(bankagetir).then(res => {
            if (res.status === 200) {
                setBanks(res.data)
            }
        })
    }

    {/* 5269110255111553 */ }

    const makePay = () => {
        axios.get(`${siparisTamamlaKKarti}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa(selectedBank)},${window.btoa(selectedTaksit)},${encode(turkish(name))},${window.btoa(localStorage.getItem('musteriNotu'))},${isTaksit ? 'MQ==' : 'MA=='},${jokerVadaa ? 'MQ==' : ''}`).then(res => {
            if (res.data[0].siparisId !== null && res.data[0].siparisId !== undefined) {
                const value = JSON.stringify(res.data[0].form)
                const skt = date.split('/')
                const mp = number.split(' ')
                let newHtml = ''

                newHtml = value.replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`).replace("name='pan' value=''", `name='pan' value='${number.replace(/\s/g, '')}'`).replace("name='Ecom_Payment_Card_ExpDate_Month' value=''", `name='Ecom_Payment_Card_ExpDate_Month' value='${skt[0]}'`).replace("name='Ecom_Payment_Card_ExpDate_Year' value=''", `name='Ecom_Payment_Card_ExpDate_Year' value='${skt[1]}'`).replace("name='cv2' value=''", `name='cv2' value='${cvc}'`).replace("name='mp' value=''", `name='mp' value='${mp[3]}'`).replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`)

                if (res.data[0].form === "-3") {
                    setNotStokModal(true)
                    setTimeout(() => {
                        window.location.href = "/basket/1"
                    }, 3000);
                } else {
                    const pageContentUrl = encode(JSON.parse(newHtml))
                    localStorage.setItem('treeDUrl', pageContentUrl)
                    window.location.href = '/treed'
                }

            } else {
                setNotModal(true)
            }
        })
    }

    const makeCari = () => {
        axios.get(`${siparisTamamlaCari}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa(selectbank)},${window.btoa(localStorage.getItem('musteriNotu'))}`).then(res => {
            if (res.data[0].durum === "1") {
                setCariMessage(`✔️${res.data[0].sonuc}`)
                setCariModal(!cariModal)
                setTimeout(() => {
                    setCariModal(false)
                    setModal(false)
                }, 3000);
            } else {
                setCariMessage(`❌${res.data[0].sonuc} : : : ${res.data[0].durum}`)
                setCariModal(!cariModal)
                setTimeout(() => {
                    setCariModal(false)
                    setModal(false)
                }, 3000);
            }
        })
    }

    const makeHavale = () => {
        axios.get(`${siparisTamamlaHavale}${window.btoa(localStorage.getItem('auth'))},${window.btoa(tesAdres.value)},${window.btoa(fatAdres.value)},${window.btoa(cargo.value)},${window.btoa(selectbank)},${window.btoa(localStorage.getItem('musteriNotu'))}`).then(res => {
            if (res.data[0].durum === "1") {
                setHavaleMessage(`✔️${res.data[0].sonuc}`)
                setHavaleModal(!havaleModal)
            } else {
                setHavaleMessage(`❌${res.data[0].sonuc} : : : ${res.data[0].durum}`)
                setHavaleModal(!havaleModal)
            }
        })
    }

    const onChangeExp = (e) => {
        SetDate(e.target.value);
        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setThird(true) } else { setThird(false) }
    };

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...payInfoVariant} className='pay-info'>
            <Header title={'ÖDEME BİLGİLERİ'} basket={basketLength} />

            <div className='sao'>
                <div className='section'><span>Sepetim</span></div>
                <div className='line'></div>
                <div className='section'><span>Adres Bilgileri</span></div>
                <div className='line'></div>
                <div className='section select'><span>Ödeme Bilgileri</span></div>
            </div>

            <div className='info'>
                <Card className='p-2 ml-4 mr-4 mt-4 pl-3 pr-3'>
                    <div className='W-100'>
                        <div className='item'>
                            <div><span>ARA TOPLAM</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.AraToplam} ₺</span></div>
                        </div>
                        <div className='item'>
                            <div><span>KARGO</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.KargoFiyati} ₺</span></div>
                        </div>
                        <div className='item'>
                            <div><span>KDV</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.KdvToplam} ₺</span></div>
                        </div>
                        {indirimWitch === 1 || indirimWitch === 2 ? data.Indirim !== undefined && data.Indirim2 !== undefined ? <div className='item'>
                            <div><span>İNDİRİM</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{indirimWitch === 1 ? data.Indirim : data.Indirim2} ₺</span></div>
                        </div> : null : null}
                        <div className='item'>
                            <div><span>TOPLAM</span></div>
                            <div className='text-right'><small>........................</small></div>
                            <div className='text-right'><span>{data.GenelToplam} ₺</span></div>
                        </div>
                    </div>
                </Card>
            </div>
            {/* indirimWitch === 1 ? parseFloat(data.GenelToplam) - parseFloat(data.Indirim) : parseFloat(data.GenelToplam) - parseFloat(data.Indirim2) */}
            <div className='odeme-seklis'>
                <div onClick={() => setSelectPay(1)} className={selectPay === 1 ? 'active' : ''}><span>Kredi Kartı</span></div>
                <div onClick={() => {
                    setSelectPay(2)
                    setIndirimWitch(1)
                    getHavaleOdeme()
                }} className={selectPay === 2 ? 'active' : 'border-left-0 border-right-0'}><span>Havale/EFT</span></div>
                <div onClick={() => { setSelectPay(3); getCariOdeme() }} className={selectPay === 3 ? 'active' : ''}><span>Cari Ödeme</span></div>
            </div>

            {
                selectPay === 1 ? <div className='view mt-4 ml-4 mr-4 mb-4'>
                    <Form>
                        <div className='mb-2'>
                            <Input
                                placeholder='Kartın Üzerindeki İsim'
                                type='input'
                                value={name}
                                onChange={e => {
                                    SetName(e.target.value)
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFirst(true) } else { setFirst(false) }
                                }}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                type="tel"
                                placeholder='Kart Numarası'
                                className='mb-2'
                                name="number"
                                value={number}
                                onBlur={() => {
                                    getTaksit()
                                }}
                                onChange={e => {
                                    if (e.target.value.length < 20) {
                                        e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                        SetNumber(e.target.value)
                                    }
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setSecond(true) } else { setSecond(false) }
                                }}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                placeholder='Son Kullanma Tarihi'
                                type="tel"
                                name="month"
                                maxLength={5}
                                onChange={onChangeExp}
                                value={expriyFormat(date)}
                            />
                        </div>
                        <div className='mb-2'>
                            <Input
                                placeholder='Güvenlik Kodu'
                                type="tel"
                                name="cvc"
                                value={cvc}
                                onChange={e => {
                                    if (e.target.value.length < 4) {
                                        SetCvc(e.target.value)
                                    } else {
                                        SetCvc(e.target.value.slice(0, 3))
                                    }
                                    if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFourth(true) } else { setFourth(false) }
                                }}
                            />
                        </div>

                        <div className='taksitler'>
                            {
                                dataTaksit.map((item, index) => {
                                    return (
                                        <div key={index} className={item.Taksit_Id !== selectedTaksitId ? 'item' : 'selectedItem shadow'} onClick={() => {
                                            setSelectedTaksitId(item.Taksit_Id);
                                            setSelectedTaksit(item.Taksit_Id);
                                            setSelectedBank(item.Taksit_KrediKarti)
                                            setIndirimWitch(item.Taksit_Sayi)
                                            if (item.Taksit_Sayi === 0) {
                                                setIsTaksit(false)
                                            } else {
                                                setIsTaksit(true)
                                            }
                                            getData()
                                        }} htmlFor="taksit">
                                            <div htmlFor="taksit">
                                                <input type='radio' id='taksit' name='taksit' className='mr-2 mt-2' />
                                                <small className='mb-1' htmlFor="taksit">{item.Taksit_Baslik}</small>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='satissozlesmesi'>
                            <Input type='checkbox' onChange={e => setTaksitOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                        </div>

                        <div className='ml-4 mt-3' htmlFor="joker">
                            <Input type='checkbox' id='joker' name='joker' onChange={() => setJokerVada(!jokerVadaa)} checked={jokerVadaa} />
                            <small htmlFor="joker">Joker Vadaa</small>
                        </div>

                        <div className='tamamla'>
                            <Button onClick={() => {
                                if (!taksitOk) {
                                    setModal(!modal)
                                } else {
                                    makePay()
                                }
                            }} disabled={first === true && second === true && third === true && fourth === true ? false : true}>SİPARİŞİ TAMAMLA</Button>
                        </div>
                    </Form>
                </div> : null
            }

            {
                selectPay === 2 ? <div className='havale'>
                    {
                        banks.map((item, index) => {
                            return (
                                <Card key={index} className={item.BankaHesap_Id === selectbank ? 'active shadow' : ''} onClick={() => setSelectbank(item.BankaHesap_Id)}>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        <div className={item.BankaHesap_Id === selectbank ? 'selected' : 'select'}></div>
                                        <div>
                                            <div><b>{item.BankaHesap_Banka}</b></div>
                                            <div><span>{item.BankaHesap_Alici}</span></div>
                                            <div><span>IBAN: {item.BankaHesap_IBan}</span></div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })
                    }
                    <div className='satissozlesmesi'>
                        <Input type='checkbox' onChange={e => setHavaleOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                    </div>
                    <div className='tamamla'>
                        <Button onClick={() => {
                            if (!havaleOk) {
                                setModal(!modal)
                            } else {
                                makeHavale()
                            }
                        }}>SİPARİŞİ TAMAMLA</Button>
                    </div>
                </div> : null
            }

            {
                selectPay === 3 ? <div className='cari-borc'>
                    <div>
                        <Card className='shadow'>
                            <div className='d-flex justify-content-start'>
                                <div className='select'></div>
                                <div><span>Cari Borç Kaydedilsin</span></div>
                            </div>
                        </Card>

                        <div className='satissozlesmesi ml-5 mt-4'>
                            <Input type='checkbox' onChange={e => setCariOk(e.target.checked)} /><span><a href='/contracts/11' className='text-dark'><b>Satış Sözleşmesi</b></a>'ni okudum ve kabul ediyorum.</span>
                        </div>

                        <div className='tamamla'>
                            <Button onClick={() => {
                                if (!cariOk) {
                                    setModal(!modal)
                                } else {
                                    makeCari()
                                }
                            }}>SİPARİŞİ TAMAMLA</Button>
                        </div>
                    </div>
                </div> : null
            }

            <Modal isOpen={havaleModal} toggle={() => setHavaleModal(!havaleModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div className='mt-2'><span>{havaleMessage}</span></div>
                </div>
            </Modal>

            <Modal isOpen={cariModal} toggle={() => setCariModal(!cariModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div className='mt-2'><span>{cariMessage}</span></div>
                </div>
            </Modal>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-danger'><b>Satış Sözleşmesi</b></div>
                    <div className='mt-2'><span>Satış sözleşmesini okuyup kabul ediniz!</span></div>
                </div>
            </Modal>

            <Modal isOpen={notModal} toggle={() => setNotModal(!notModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div><small>Ödeme işleminde sorun oluştu lütfen tekrar deneyiniz.</small></div>
                </div>
            </Modal>

            <Modal isOpen={notStokModal} toggle={() => setNotStokModal(!notStokModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div>Sepette bulunan ürünleriniz arasında stoğumuzda bulunmayan ürünler bulunmaktadır. Stokta bulunmayan ürünler kaldırılacaktır. Lütfen tekrar deneyiniz.</div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div >
    )
}

export default PayInfo