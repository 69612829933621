import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { formVariant, mobilVariant } from '../../components/animation'
import axios from 'axios'
import { adresduzenle, adresigetir, musteriadresaydet, sehirIlceGetir, sepetigetir } from '../../api/api'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { Form, Input, Button, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { adresTuru } from '../../components/options'
import { encode } from '../../components/general'

const animatedComponents = makeAnimated();

const AddressEdit = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [AddressType, setAddressType] = useState('1')
    const [cityId, setCityId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const [currentStatus1, setCurrentStatus1] = useState({})
    const [currentStatus2, setCurrentStatus2] = useState({ value: 0, label: 'Şehir seçiniz' })
    const [currentStatus3, setCurrentStatus3] = useState({ value: 0, label: 'İlçe seçiniz' })
    const [dangerOpen, setDangerOpen] = useState(false)
    const [danger, setDanger] = useState('')
    const [data, setData] = useState([])

    const [cities, setCities] = useState([])
    const [district, setDistrict] = useState([])

    const { handleSubmit } = useForm()
    const { id } = useParams()

    const getData = () => {
        axios.get(`${sehirIlceGetir}MQ==`).then(res => {
            const value = res.data
            const cityList = []
            for (let i = 0; i < res.data.length; i++) {
                cityList.push({ value: res.data[i].Usis_Id, label: res.data[i].Usis_Ad })
            }
            setCities(cityList)
        })

        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setBasketLength(`${res.data.length}`)
            }
        })

        if (id !== '0') {
            axios.get(`${adresigetir}${window.btoa(id)}`).then(res => {
                if (res.status === 200) {
                    if (res.data !== undefined && res.data !== null) {
                        const value = res.data[0]
                        setData(value)
                        setCurrentStatus1({ label: value.MusteriAdres_Tur, label: value.MusteriAdres_Tur === 1 ? 'Teslimat Adresi' : 'Fatura Adresi', value: value.MusteriAdres_Tur })
                        setCurrentStatus2({ label: value.MusteriAdres_SehirAd, value: 0 })
                        setAddressType(value.MusteriAdres_Tur === 1 ? '1' : '2')
                    }
                }
            })
        }
    }

    const getDistrict = (cityIds) => {
        axios.get(`${sehirIlceGetir}${window.btoa(cityIds)}`).then(res => {
            const value = res.data
            const districtList = []
            for (let i = 0; i < res.data.length; i++) {
                districtList.push({ value: res.data[i].Usis_Id, label: res.data[i].Usis_Ad })
            }
            setDistrict(districtList)
        })
    }

    const onSubmit = (values, e) => {
        const addressName = e.target[0].value
        const name = e.target[2].value
        const surname = e.target[3].value
        const address = e.target[6].value
        const phone = e.target[7].value
        const defaultAddress = e.target[8].checked

        axios.get(`${id !== '0' ? adresduzenle : musteriadresaydet}${id !== '0' ? encode(addressName) : window.btoa(unescape(encodeURIComponent(addressName)))},${id !== '0' ? encode(AddressType) : window.btoa(unescape(encodeURIComponent(AddressType)))},${id !== '0' ? encode(name) : window.btoa(unescape(encodeURIComponent(name)))},${id !== '0' ? encode(surname) : window.btoa(unescape(encodeURIComponent(surname)))},${id !== '0' ? encode(cityId) : window.btoa(unescape(encodeURIComponent(cityId)))},${id !== '0' ? encode(districtId) : window.btoa(unescape(encodeURIComponent(districtId)))},${id !== '0' ? encode(address) : window.btoa(unescape(encodeURIComponent(address)))},${id !== '0' ? encode(phone) : window.btoa(unescape(encodeURIComponent(phone)))},${!defaultAddress ? id !== 0 ? encode('Hayır') : window.btoa(unescape(encodeURIComponent('Hayır'))) : id !== '0' ? encode('Evet') : window.btoa(unescape(encodeURIComponent('Evet')))},${window.btoa(localStorage.getItem('auth'))},${window.btoa(`${id}`)}${id !== '0' ? null : `,${window.btoa(unescape(encodeURIComponent(false)))}`}`).then(res => {
            if (res.status === 200) {
                if (res.data[0].durum === '0') {
                    setDangerOpen(!dangerOpen)
                    setDanger(res.data[0].sonuc)
                }
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='address-edit'>
            <Header basket={basketLength} title={'ADRES DÜZENLE'} />

            <motion.div className='duzenle' {...formVariant}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <span>Adres Adı</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Baslik : null} />
                    <span>Adres Türü</span>
                    <Select
                        noOptionsMessage={() => 'Adres Türü Bulunamadı!'}
                        isClearable={false}
                        className="react-select"
                        classNamePrefix="select"
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        options={adresTuru}
                        components={animatedComponents}
                        value={currentStatus1}
                        onChange={data => {
                            setCurrentStatus1(data);
                            setAddressType(data.value);
                        }}
                    />
                    <span>Ad</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Ad : null} />
                    <span>Soyad</span>
                    <Input type='input' defaultValue={id !== '0' ? data.MusteriAdres_Soyad : null} />
                    <span>Şehir</span>
                    <Select
                        noOptionsMessage={() => 'Şehir Bulunamadı!'}
                        isClearable={false}
                        className="react-select mb-2"
                        classNamePrefix="select"
                        options={cities}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        components={animatedComponents}
                        value={currentStatus2}
                        onChange={data => {
                            setCurrentStatus2(data);
                            setCityId(data.value)
                            getDistrict(data.value)
                        }}
                    />
                    <span>İlçe</span>
                    <Select
                        noOptionsMessage={() => 'İlçe Bulunamadı!'}
                        isClearable={false}
                        className="react-select mb-2"
                        classNamePrefix="select"
                        options={district}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                fontSize: 13,
                            }),
                        }}
                        isDisabled={cityId === ""}
                        components={animatedComponents}
                        value={currentStatus3}
                        onChange={data => {
                            setCurrentStatus3(data);
                            setDistrictId(data.value)
                        }}
                    />
                    <span>Açık Adres</span>
                    <Input type='textarea' style={{ fontSize: 13 }} className='mb-2' defaultValue={id !== '0' ? data.MusteriAdres_Adres : null} />
                    <span>Telefon</span>
                    <Input type='tel' defaultValue={id !== '0' ? data.MusteriAdres_Telefon : null} />
                    <div><span>Varsayılan Adres</span></div>
                    <div>
                        <label className="switch">
                            <Input type="checkbox" defaultChecked={id !== '0' ? data.MusteriAdres_Varsayilan : null} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <Button>ADRESİ KAYDET</Button>
                </Form>
            </motion.div>

            <Modal isOpen={dangerOpen} toggle={() => setDangerOpen(!dangerOpen)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><span style={{ fontSize: 13 }}>{danger}</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='bg-primary border-0' style={{ fontSize: 13, borderRadius: 15 }} onClick={() => setDangerOpen(!dangerOpen)}>
                            Tamam</Button>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default AddressEdit