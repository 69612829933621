import React from 'react'

const TreeD = () => {
    const data = localStorage.getItem('treeDUrl')
    const url = 'data:text/html;base64,' + data.split('/')[0]

    return (
        <div>
            <div><iframe id="id_description_iframe" src={url} style={{ height: '100vh', width: '100%' }} /></div>
        </div>
    )
}

export default TreeD