export const cities = [
    { value: 0, label: 'Seçim Yapınız' },
    { value: 240, label: 'Adana' },
    { value: 241, label: 'Adıyaman' },
    { value: 242, label: 'Afyonkarahisar' },
    { value: 243, label: 'Ağrı' },
    { value: 244, label: 'Aksaray' },
    { value: 245, label: 'Amasya' },
    { value: 238, label: 'Ankara' },
    { value: 246, label: 'Antalya' },
    { value: 247, label: 'Ardahan' },
    { value: 248, label: 'Artvin' },
    { value: 249, label: 'Aydın' },
    { value: 250, label: 'Balıkesir' },
    { value: 251, label: 'Bartın' },
    { value: 252, label: 'Batman' },
    { value: 253, label: 'Bayburt' },
    { value: 254, label: 'Bilecik' },
    { value: 255, label: 'Bingöl' },
    { value: 256, label: 'Bitlis' },
    { value: 257, label: 'Bolu' },
    { value: 258, label: 'Burdur' },
    { value: 259, label: 'Bursa' },
    { value: 260, label: 'Çanakkale' },
    { value: 261, label: 'Çankırı' },
    { value: 262, label: 'Çorum' },
    { value: 263, label: 'Denizli' },
    { value: 264, label: 'Diyarbakır' },
    { value: 265, label: 'Düzce' },
    { value: 266, label: 'Edirne' },
    { value: 267, label: 'Elazığ' },
    { value: 268, label: 'Erzincan' },
    { value: 269, label: 'Erzurum' },
    { value: 270, label: 'Eskişehir' },
    { value: 271, label: 'Gaziantep' },
    { value: 272, label: 'Giresun' },
    { value: 273, label: 'Gümüşhane' },
    { value: 274, label: 'Hakkari' },
    { value: 275, label: 'Hatay' },
    { value: 276, label: 'Iğdır' },
    { value: 277, label: 'Isparta' },
    { value: 237, label: 'İstanbul Anadolu' },
    { value: 236, label: 'İstanbul Avrupa' },
    { value: 239, label: 'İzmir' },
    { value: 278, label: 'Kahramanmaraş' },
    { value: 279, label: 'Karabük' },
    { value: 280, label: 'Karaman' },
    { value: 281, label: 'Kars' },
    { value: 282, label: 'Kastamonu' },
    { value: 283, label: 'Kayseri' },
    { value: 287, label: 'Kilis' },
    { value: 284, label: 'Kırıkkale' },
    { value: 285, label: 'Kırklareli' },
    { value: 286, label: 'Kırşehir' },
    { value: 288, label: 'Kocaeli' },
    { value: 289, label: 'Konya' },
    { value: 290, label: 'Kütahya' },
    { value: 291, label: 'Malatya' },
    { value: 292, label: 'Manisa' },
    { value: 293, label: 'Mardin' },
    { value: 294, label: 'Mersin' },
    { value: 295, label: 'Muğla' },
    { value: 296, label: 'Muş' },
    { value: 297, label: 'Nevşehir' },
    { value: 298, label: 'Niğde' },
    { value: 299, label: 'Ordu' },
    { value: 300, label: 'Osmaniye' },
    { value: 301, label: 'Rize' },
    { value: 302, label: 'Sakarya' },
    { value: 303, label: 'Samsun' },
    { value: 307, label: 'Şanlıurfa' },
    { value: 304, label: 'Siirt' },
    { value: 305, label: 'Sinop' },
    { value: 308, label: 'Şırnak' },
    { value: 306, label: 'Sivas' },
    { value: 309, label: 'Tekirdağ' },
    { value: 310, label: 'Tokat' },
    { value: 311, label: 'Trabzon' },
    { value: 312, label: 'Tunceli' },
    { value: 313, label: 'Uşak' },
    { value: 314, label: 'Van' },
    { value: 315, label: 'Yalova' },
    { value: 316, label: 'Yozgat' },
    { value: 317, label: 'Zonguldak' }
]

export const adresTuru = [
    { value: 1, label: 'Teslimat Adresi' },
    { value: 2, label: 'Fatura Adresi' }
]