import React, { useEffect, useState } from 'react'
import { paymentsVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import { odemeGecmisi, sepetigetir } from '../../api/api'
import axios from 'axios'
import BottomBar from '../../components/bottombar'
import { Input } from 'reactstrap'
import print from '../../img/print.png'

const MyPayments = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [data, setData] = useState([])
    const [initDate, setInitDate] = useState('25.01.2023')
    const [endDate, setEndDate] = useState(new Date().toLocaleDateString())

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })
    }

    const getDate = () => {
        axios.get(`${odemeGecmisi}${window.btoa(localStorage.getItem('auth'))},${window.btoa(initDate)},${window.btoa(endDate)}`).then(res => {
            if (res.status === 200) {
                setData(res.data)
            }
        })
    }

    useEffect(() => { getData(); getDate() }, [])

    return (
        <motion.div {...mobilVariant} className='my-payments'>
            <Header title={'ÖDEMELERİM'} basket={basketLength} />

            <div className='date-print'>
                <div>
                    <Input type='date' onChange={e => { setInitDate(e.target.value); getDate(); }} />
                </div>
                <div>
                    <Input type='date' onChange={e => { setEndDate(e.target.value); getDate(); }} />
                </div>
                <div>
                    <img src={print} alt='' />
                </div>
            </div>

            <div id='tableview'>
                {data !== undefined && data !== null ? data.length !== 0 ? <div className='tables'>
                    <div><b>ÖDEME NO</b></div>
                    <div><b>ÖDEME TARİHİ</b></div>
                    <div><b>KART SAHİBİ</b></div>
                    <div><b>TOPLAM TUTAR</b></div>
                </div> : null : null}

                <motion.div {...paymentsVariant}>
                    {data !== undefined && data !== null ? data.length !== 0 ? <div className='tabless'>
                        {
                            data.map((item, index) => {
                                return (
                                    <div className='item' key={index}>
                                        <div><small>{item.OzelOdeme_Id}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{new Date(item.OzelOdeme_Tarih).toLocaleDateString()}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{item.OzelOdeme_KartSahibi}</small></div>
                                        <div style={{ marginLeft: 20 }}><small>{item.OzelOdeme_OdemeTutar}₺</small></div>
                                    </div>
                                )
                            })
                        }
                    </div> : null : null}
                </motion.div>
            </div>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default MyPayments