import { Route, Routes, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import Account from '../pages/account/account'
import Login from '../pages/auth/login'
import HomePage from '../pages/homepage/homepage'
import Contracts from '../pages/contract/contracts'
import Product from '../pages/product/product'
import ProductDetail from '../pages/product/productdetail'
import Basket from '../pages/basket/basket'
import Pay from '../pages/pay/pay'
import Search from '../pages/search/search'
import Profile from '../pages/profile/profile'
import Orders from '../pages/order/orders'
import OrderDetail from '../pages/order/orderdetail'
import Address from '../pages/address/address'
import AddressEdit from '../pages/address/addressedit'
import Register from '../pages/auth/register'
import MyPayments from '../pages/mypayments/mypayments'
import About from '../pages/about/about'
import AddressInfo from '../pages/basket/addressInfo'
import PayInfo from '../pages/basket/payInfo'
import TreeD from '../pages/treed'

const AnimatedRoutes = () => {
    const location = useLocation()
    const isLogin = localStorage.getItem('auth')

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={isLogin === undefined || isLogin === null ? <Login /> : <HomePage />} />
                <Route path='/login' element={isLogin === undefined || isLogin === null ? <Login /> : <HomePage />} />
                <Route path='/register' element={isLogin === undefined || isLogin === null ? <Register /> : <HomePage />} />
                <Route path='/contracts/:id' element={isLogin === undefined || isLogin === null ? <Contracts /> : <Contracts />} />
                <Route path='/product/:id/:isBrand' element={isLogin === undefined || isLogin === null ? <Login /> : <Product />} />
                <Route path='/product-detail/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <ProductDetail />} />
                <Route path='/basket/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <Basket />} />
                <Route path='/pay' element={isLogin === undefined || isLogin === null ? <Login /> : <Pay />} />
                <Route path='/search' element={isLogin === undefined || isLogin === null ? <Login /> : <Search />} />
                <Route path='/profile' element={isLogin === undefined || isLogin === null ? <Login /> : <Profile />} />
                <Route path='/orders' element={isLogin === undefined || isLogin === null ? <Login /> : <Orders />} />
                <Route path='/order-detail/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <OrderDetail />} />
                <Route path='/address' element={isLogin === undefined || isLogin === null ? <Login /> : <Address />} />
                <Route path='/address-edit/:id' element={isLogin === undefined || isLogin === null ? <Login /> : <AddressEdit />} />
                <Route path='/account' element={isLogin === undefined || isLogin === null ? <Login /> : <Account />} />
                <Route path='/my-payments' element={isLogin === undefined || isLogin === null ? <Login /> : <MyPayments />} />
                <Route path='/about' element={isLogin === undefined || isLogin === null ? <Login /> : <About />} />
                <Route path='/address-info' element={isLogin === undefined || isLogin === null ? <Login /> : <AddressInfo />} />
                <Route path='/pay-info' element={isLogin === undefined || isLogin === null ? <Login /> : <PayInfo />} />
                <Route path='/treed' element={isLogin === undefined || isLogin === null ? <Login /> : <TreeD />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes