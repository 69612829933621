import React from 'react'
import logo from '../img/logo.png'
import shopping from '../img/shopping.png'
import search from '../img/search.png'
import '../css/style.css'
import { ChevronLeft } from 'react-feather'

const Header = ({ title, basket }) => {
    return (
        <div className='header-navbar'>
            <div className={title === '' ? 'text-white' : 'text-secondary'}>{title === '' ? 'dsfsdfsdfs' : <div className='ml-2' onClick={() => window.history.back()}><ChevronLeft size={30} color='grey' /></div>}</div>
            <div className='logo'>{title === '' ? <img src={logo} alt='' /> : <div dangerouslySetInnerHTML={{ __html: title }}></div>}</div>
            <div className='ss'>
                {title !== 'ARAMA YAP' ? <div onClick={() => window.location.href = '/search'}><img src={search} alt='' /></div> : null}
                <div onClick={() => window.location.href = '/basket/0'}>
                    <div><img src={shopping} alt='' /></div>
                    <div className='basket'><span>{basket}</span></div>
                </div>
            </div>
        </div>
    )
}

export default Header