import React, { useEffect, useState } from 'react'
import '../css/style.css'
import home from '../img/home.png'
import product from '../img/product.png'
import shopping from '../img/shopping.png'
import odeme from '../img/brands.png'
import account from '../img/account.png'
import profil from '../img/profil.png'
import Drawer from 'react-motion-drawer'
import { solmenu, akinsofttanBakiyeSorgulaSite, puanbilgisi } from '../api/api'
import axios from 'axios'
import { ChevronDown, ChevronRight, ChevronUp } from 'react-feather'
import { submenuVariant } from './animation'
import { motion } from 'framer-motion'
import { paraFormati } from '../components/general.js'

const BottomBar = ({ basket }) => {
    const [canvasOpen, setCanvasOpen] = useState(false)
    const [leftmenu, setLeftmenu] = useState([])
    const [accordId, setAccordId] = useState(0)
    const [newPuan, setNewPuan] = useState()
    const [bakiye, setBakiye] = useState([])
    const info = JSON.parse(localStorage.getItem('info'))
    const days = window.btoa(`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`)

    const menuItems = () => {
        let listElements = document.querySelectorAll('.link');

        listElements.forEach(listElement => {
            listElement.addEventListener('click', () => {
                if (listElement.classList.contains('active')) {
                    listElement.classList.remove('active');
                } else {
                    listElements.forEach(listE => {
                        listE.classList.remove('active');
                    })
                    listElement.classList.toggle('active');
                }
            })
        });
    }

    const getData = () => {
        axios.get(`${solmenu}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setLeftmenu(value)
                menuItems()
            }
        })

        axios.get(`${puanbilgisi}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setNewPuan(JSON.stringify(res.data))
            }
        })

        axios.get(`${akinsofttanBakiyeSorgulaSite}${info.MusteriTicariId}`).then(res => {
            if (res.status === 200) {
                if (res.data !== null && res.data !== undefined) {
                    setBakiye(JSON.parse(res.data)[0])
                }
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <div className='bottom-bar'>
            <div onClick={() => window.location.href = '/'} className='item'>
                <div><img src={home} alt='' /></div>
                <div className='text'><span>Asil Otogaz</span></div>
            </div>
            <div onClick={() => setCanvasOpen(!canvasOpen)} className='item'>
                <div><img src={product} alt='' /></div>
                <div className='text'><span>Ürünler</span></div>
            </div>
            <div onClick={() => window.location.href = '/basket/0'} className='item'>
                <div><img src={shopping} alt='' /></div>
                <div className='adest'>{basket}</div>
                <div className='text'><span>Sepet</span></div>
            </div>
            <div onClick={() => window.location.href = '/pay'} className='item'>
                <div><img src={odeme} alt='' /></div>
                <div className='text'><span>Ödeme Yap</span></div>
            </div>
            <div onClick={() => window.location.href = '/profile'} className='item'>
                <div><img src={account} alt='' /></div>
                <div className='text'><span>Hesabım</span></div>
            </div>

            <div className='drawer'>
                <Drawer open={canvasOpen} toggle={() => setCanvasOpen(!canvasOpen)} className='drawermenu'>
                    <div className='drawer-index'>
                        <div><img src={profil} alt='' /></div>
                        <div className='text-center text-secondary mt-3'><span>{info.MusteriAd} {info.MusteriSoyad}</span></div>
                        <div className='d-flex justify-content-center' onClick={() => window.location.href = '/account'}>
                            <div className='border border-secondary p-1 mt-3 w-50 text-secondary'>Profili Görüntüle</div>
                        </div>
                        <div className='puan'>
                            <div className='mt-2'><span>Bakiye: </span><span>{bakiye !== undefined ? paraFormati(bakiye.BAKIYE) : "0,00"} ₺</span></div>
                            <div><span>Puan: </span><span>{newPuan !== undefined ? newPuan.replace('"', "").replace('"', "") : '0 ₺'}</span></div>
                        </div>
                        <hr />
                        <div className='urunler'>
                            <div className='text-left mb-2'><h5><b>ÜRÜNLER</b></h5></div>
                            {
                                leftmenu.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className='item w-100'>
                                                <div className='title' dangerouslySetInnerHTML={{ __html: item.baslik }} onClick={() => window.location.href = `/product/${item.id}/0`}></div>

                                                {
                                                    item.altKategoriler.length !== 0 ? <div className='arrowdown align-items-center' onClick={() => setAccordId(item.id)}>{accordId === item.id ? <ChevronUp color='grey' size={23} /> : <ChevronDown color='grey' size={23} />}</div> : null
                                                }
                                            </div>
                                            {item.id === accordId ? item.altKategoriler.length !== 0 ? <motion.div {...submenuVariant} className='submenu'>
                                                {
                                                    item.altKategoriler.map((a, b) => {
                                                        return (
                                                            <div key={b}><ChevronRight size={15} color='grey' className='mb-1' /><span dangerouslySetInnerHTML={{ __html: a.baslik }} onClick={() => window.location.href = `/product/${a.id}/0`}></span></div>
                                                        )
                                                    })
                                                }
                                            </motion.div> : null : null}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Drawer >
            </div >
        </div >
    )
}

export default BottomBar