import React, { useEffect, useState } from 'react'
import { mobilVariant, productEnter } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import arrow from '../../img/arrow.png'
import loads from '../../img/loading.svg'
import { Button, Input, Modal } from 'reactstrap'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import loadimg from '../../img/loading.svg'
import { markaUrunListeleme, sepeteekle, sepetigetir, urunlisteleme } from '../../api/api'
import { Minus, Plus } from 'react-feather'

const Product = () => {
    const { id, isBrand } = useParams()
    const [baslik, setBaslik] = useState('')
    const [data, setData] = useState([])
    const [basketLength, setBasketLength] = useState('0')
    const [openModal, setOpenModal] = useState(false)
    const [sorted, setSorted] = useState('0')
    const [loading, setLoading] = useState(false)
    const [loadProduct, setLoadProduct] = useState(false)
    const [basketEnter, setBasketEnter] = useState(false)
    const [selectedId, setSelectedId] = useState(0)
    const [adet, setAdet] = useState({ id: 0, number: 1 })
    const [dontAddBasket, setDontAddBasket] = useState(false)
    const [message, setMessage] = useState('')
    const [notProduct, setNotProduct] = useState(false)
    const [stokModal, setStokModal] = useState(false)

    const getProduct = () => {
        axios.get(`${isBrand === '0' ? urunlisteleme : markaUrunListeleme}${window.btoa(id)},${window.btoa(sorted)},${window.btoa(localStorage.getItem('auth'))},${window.btoa('0')},MTAwMA==`).then(res => {
            if (res.data !== null && res.data !== undefined) {
                if (res.status === 200) {
                    const value = res.data
                    setBaslik(value[0].UrunKategori_Baslik)
                    setData(value)
                    setLoading(true)
                }
            } else {
                setNotProduct(true)
            }
        })
        setLoading(false)
        setTimeout(() => {
            if (!loading) {
                setLoading(true)
            }
        }, 700);
        setTimeout(() => {
            if (!loadProduct) {
                setLoadProduct(true)
            }
        }, 1400);
    }

    const getData = () => {
        getProduct()

        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })
    }

    const addBasket = (id) => {
        setBasketEnter(true)

        axios.get(`${sepeteekle}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa(adet.number)},${window.btoa('1')},${window.btoa('1')}`).then(res => {
            if (res.data[0].durum === "1") {
                if (res.status === 200) {
                    axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                        if (res.status === 200) {
                            const value = res.data
                            setBasketLength(`${value.length}`)
                        }
                    })
                }
            } else {
                setDontAddBasket(!dontAddBasket)
                setMessage(res.data[0].sonuc)
            }
        })
    }

    useEffect(() => {
        if (!basketEnter) {
            getData()
        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='product'>
            <Header title={baslik} basket={basketLength} />
            <div className='filter'>
                <div onClick={() => setOpenModal(!openModal)}>
                    <img src={arrow} alt='' />
                    <span>Sırala</span>
                </div>
                <div>
                    <span>{data.length} ürün</span>
                </div>
            </div>

            {loading ? <motion.div {...productEnter} className='urunlers'>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className='ml-2 mr-2 d-flex justify-content-center mb-4 bg-white roundend p-1 align-items-end text-center'>
                                {loadProduct ? <div className='border-0 pb-3 m-1'>
                                    <div className='mb-3' onClick={() => window.location.href = `/product-detail/${item.Urun_Id}`}>
                                        <div><img src={item.Medya} alt='' /></div>
                                        <div className='productTitle'><span>{item.Urun_Baslik}</span></div>
                                        <div><small class="fw-bold">Ürün Kodu: </small><small class="fw-bold">{item.Urun_TicariYazilimId}</small></div>
                                        <div><span class="fw-bold">Fiyatı</span></div>
                                        <div><span class="fw-bold">{item.Etiket} ₺</span></div>
                                    </div>
                                    <div className='adet'>
                                        <div><Minus color='red' onClick={() => {
                                            if (adet.number > 1) {
                                                setAdet({ id: item.Urun_Id, number: adet.number - 1 })
                                            }
                                        }} /></div>
                                        <div><Input type='number' value={item.Urun_Id === adet.id ? adet.number : 1} onChange={e => {
                                            setAdet({ id: item.Urun_Id, number: parseInt(e.target.value) })
                                        }} /></div>
                                        <div><Plus color='red' onClick={() => {
                                            setAdet({ id: item.Urun_Id, number: adet.number + 1 })
                                        }} /></div>
                                    </div>
                                    <div className='mt-2'>
                                        <Button onClick={() => {
                                            if (item.Stok > 0) {
                                                addBasket(item.Urun_Id);
                                                setSelectedId(item.Urun_Id)
                                                setTimeout(() => {
                                                    setSelectedId(0)
                                                }, 1900)
                                            } else {
                                                setStokModal(true)
                                            }
                                        }}>Sepete Ekle</Button>
                                        <div className={item.Urun_Id === selectedId ? 'animationimg' : 'notimg'}>
                                            <div className='arti1'><span>+1</span></div>
                                        </div>
                                    </div>
                                </div> : <div className='initProduct'>
                                    <img src={loadimg} alt='' />
                                </div>}
                            </div>
                        )
                    })
                }
            </motion.div> : <div className='initProducts'>
                <img src={loads} alt='' />
            </div>}

            <Modal isOpen={openModal} toggle={() => setOpenModal(!openModal)} className='modal-dialog-centered'>
                <div className='modal-filter'>
                    <div><b>Ürün Sırala</b></div>
                    <div className='mt-4'>
                        <div><Button onClick={() => { setSorted('0'); getProduct(); setOpenModal(!openModal) }}>A'dan Z'ye Sıralı</Button></div>
                        <div className='mt-2'><Button onClick={() => { setSorted('1'); getProduct(); setOpenModal(!openModal) }}>Z'den A'ya Sıralı</Button></div>
                        <div className='mt-2'><Button onClick={() => { setSorted('2'); getProduct(); setOpenModal(!openModal) }}>Çok Satan</Button></div>
                        <div className='mt-2'><Button onClick={() => { setSorted('3'); getProduct(); setOpenModal(!openModal) }}>En Yeniler</Button></div>
                        <div className='mt-2'><Button onClick={() => { setSorted('4'); getProduct(); setOpenModal(!openModal) }}>En Düşük Fiyat</Button></div>
                        <div className='mt-2'><Button onClick={() => { setSorted('5'); getProduct(); setOpenModal(!openModal) }}>En Yüksek Fiyat</Button></div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={dontAddBasket} toggle={() => setDontAddBasket(!dontAddBasket)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h4>Asil Grup</h4></div>
                    <div>{message}</div>
                </div>
            </Modal>

            <Modal isOpen={notProduct} toggle={() => setNotProduct(!notProduct)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div>Seçtiğiniz markaya ait ürün bulunamadı!</div>
                    <div className='d-flex justify-content-center m-3'>
                        <Button className='border border-danger text-danger bg-white px-3 py-0' onClick={() => window.history.back()}>Alışverişe Dön</Button>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={stokModal} toggle={() => setStokModal(!stokModal)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><h5>Stok Yok!</h5></div>
                    <div><span>Ürün stoğu bulunmamaktadır.</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button color='danger' className='px-3 py-0' onClick={() => setStokModal(false)}>Tamam</Button>
                    </div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='px-3 py-0 border border-danger bg-white text-danger' onClick={() => window.location.href = '/'}>Ana Sayfa</Button>
                    </div>
                </div>
            </Modal>
            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Product