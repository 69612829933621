import React, { useEffect, useState } from 'react'
import { mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import axios from 'axios'
import { sepetigetir, siparisdetay } from '../../api/api'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { useParams } from 'react-router-dom'
import logo from '../../img/logo.png'
import print from '../../img/print.png'
import { Card } from 'reactstrap'

const OrderDetail = () => {
  const [basketLength, setBasketLength] = useState('0')
  const [data, setData] = useState([])
  const { id } = useParams()

  const getData = () => {
    axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
      if (res.status === 200) {
        setBasketLength(`${res.data.length}`)
      }
    })

    axios.get(`${siparisdetay}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
      if (res.status === 200) {
        console.log(res.data)
        setData(res.data)
      }
    })
  }

  useEffect(() => { getData() }, [])

  return (
    <motion.div {...mobilVariant} className='order-detail'>
      <Header basket={basketLength} title={'SİPARİŞ DETAY'} />

      <div className='prints'>
        <div className='d-flex justify-content-between p-3'>
          <div></div>
          <div><img src={logo} alt='' style={{ height: 100 }} /></div>
          <div onClick={() => { window.print() }}><img src={print} alt='' style={{ height: 20 }} /></div>
        </div>
        <div className='text-center'><small>Sipariş Detaylarınız Aşağıda Listelenmektedir.</small></div>
        <Card className='shadow text-center align-items-center mt-2'>
          <div><small>Sipariş Kodunuz</small></div>
          <div className='text-danger'><small>{data.Table1 !== undefined ? data.Table1[0].Siparis_Kodu : 'Bulunamadı'}</small></div>
        </Card>
      </div>

      <div className='fiyat-miktar'>
        <Card className='shadow'>
          <div className='d-flex justify-content-between pt-1 pb-1 align-items-center'>
            <div className='ml-2 mr-2'>{data.Table2 !== undefined ? <img src={data.Table2[0].Medya} alt='' width={60} /> : <img src={logo} alt='' width={60} />}</div>

            <div>
              <div><small style={{ display: 'block' }}>{data.Table2 !== undefined ? data.Table2[0].SiparisDetay_UrunAd : 'Ürün Adı Bulunamadı!'}</small></div>
              <div><small>URUN KODU: {data.Table2 !== undefined ? data.Table2[0].SiparisDetay_StokTicariKod : ''}</small></div>
            </div>

            <div className='text-center'>
              <div><b>Fiyat</b></div>
              <div><small>{data.Table2 !== undefined ? data.Table2[0].SiparisDetay_UrunTlTutar : ''}₺</small></div>
            </div>

            <div className='ml-3 mr-3 text-center'>
              <div><b>Miktar</b></div>
              <div><small>{data.Table2 !== undefined ? data.Table2[0].SiparisDetay_Miktar : ''}</small></div>
            </div>
          </div>
        </Card>
      </div>

      <div className='siparis-tutar'>
        <Card className='shadow'>
          <div><b>Sipariş Tutarı</b></div>
          <div>
            <div><small>Sipariş Tutarı: {data.Table1 !== undefined ? data.Table1[0].Siparis_SepetToplamTutari : '0 ₺'}</small></div>
            <div style={{ marginTop: -5 }}><small>Sipariş KDV Tutarı: {data.Table1 !== undefined ? data.Table1[0].Siparis_SepetToplamKdvTutari : '0 ₺'}</small></div>
          </div>
        </Card>
      </div>

      <div className='teslimat'>
        <Card>
          <div><b>Teslimat ve Fatura Adresi</b></div>
          <div className='mt-2'><b className='text-dark'>Teslimat Adresiniz</b></div>
          <div style={{ marginTop: -5 }}><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_TeslimatAdresi : '' }}></small></div>
          <div className='mt-1'><b className='text-dark'>Fatura Adresiniz</b></div>
          <div style={{ marginTop: -5 }}><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_FaturaAdresi : '' }}></small></div>
          <div className='mt-1'><b className='text-dark'>Kargonuz</b></div>
          <div style={{ marginTop: -5 }}><small><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoAd : '' }}></small> | <small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoTutari : '' }}></small>,00 ₺ | Takip Kodu: <small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_KargoTakipKod : '' }}></small></small></div>
        </Card>
      </div>

      <div className='odeme-yontem'>
        <Card>
          <div><b>Ödeme Yöntemi</b></div>
          <div><b className='text-dark'>Havale/EFT</b></div>
          <div><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Odeme_Yontemi : '' }}></small></div>
        </Card>
      </div>

      <div className='odeme-notu'>
        <Card>
          <div><b>Ödeme Notu</b></div>
          <div><small dangerouslySetInnerHTML={{ __html: data.Table1 !== undefined ? data.Table1[0].Siparis_Aciklama : '' }}></small></div>
        </Card>
      </div>

      <BottomBar basket={basketLength} />
    </motion.div>
  )
}

export default OrderDetail