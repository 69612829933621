import React, { useEffect, useState } from 'react'
import { mobilVariant, profileVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { sepetigetir, uyeSilme } from '../../api/api'
import { Button, Modal } from 'reactstrap'

const Profile = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [modal, setModal] = useState(false)
    const [statusModal, setStatusModal] = useState(false)
    const [statusMessage, setStatusMessage] = useState("")
    const info = JSON.parse(localStorage.getItem('info'))

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setBasketLength(`${res.data.length}`)
            }
        })
    }

    const delAccount = () => {
        const value = JSON.parse(localStorage.getItem('info'))
        axios.get(`${uyeSilme}${window.btoa(localStorage.getItem('auth'))},${window.btoa(value.MusteriAd)},${window.btoa(value.MusteriSoyad)},${window.btoa(value.MusteriTelefon)},${window.btoa(value.MusteriDogumTarihi)},${window.btoa(value.MusteriSifre)},${window.btoa(value.MusteriSifre)},${window.btoa(value.MusteriFirmaUnvani)},${window.btoa(value.MusteriVergiNumarasi)},${window.btoa(value.MusteriVergiDairesi)}`).then(res => {
            if (res.data[0].durum === "1") {
                setTimeout(() => { setModal(!modal) }, 600)
                setStatusMessage(res.data[0].sonuc)
                setStatusModal(!statusModal)
                setTimeout(() => {
                    setStatusModal(!statusModal)
                }, 1400)
                setTimeout(() => {
                    localStorage.clear()
                    window.location.href = '/login'
                }, 1200)
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='profile'>
            <Header basket={basketLength} title={'PROFİLİM'} />

            <div className='name'>{info.MusteriAd} {info.MusteriSoyad}</div>

            <motion.div className='menus' {...profileVariant}>
                <div onClick={() => window.location.href = '/orders'}><span>SİPARİŞLERİM</span></div>
                <div onClick={() => window.location.href = '/address'}><span>ADRESLERİM</span></div>
                <div onClick={() => window.location.href = '/account'}><span>HESABIM</span></div>
                <div onClick={() => window.location.href = '/my-payments'}><span>ÖDEMELERİM</span></div>
                <div onClick={() => window.location.href = '/pay'}><span>ÖDEME YAP</span></div>
                <div onClick={() => window.location.href = '/about'}><span>ASİL OTOGAZ HAKKINDA</span></div>
                <div onClick={() => setModal(!modal)}><span>HESABI SİL </span></div>
                <div onClick={() => { localStorage.clear(); window.location.href = '/login' }}><span>ÇIKIŞ</span></div>
            </motion.div>

            <BottomBar basket={basketLength} />

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-center'><h5>Asil Otogaz</h5></div>
                    <div><small>Hesabınızı silmek istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-around mt-3'>
                        <div className='w-50'>
                            <Button color='danger' className='p-1 w-100' onClick={() => delAccount()}>Evet</Button>
                        </div>
                        <div className='w-50'>
                            <Button color='primary' className='p-1 w-100' onClick={() => setModal(!modal)}>Hayır</Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={statusModal} toggle={() => setStatusModal(!statusModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div className='text-center'><h5>Asil Otogaz</h5></div>
                    <div className='mt-3 mb-2'><small>{statusMessage}</small></div>
                </div>
            </Modal>
        </motion.div>
    )
}

export default Profile