export const mobilVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const nullBasketVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginTop: 100
    },
    animate: {
        opacity: 1,
        margin: 0,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const submenuVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        marginLeft: 20
    },
    animate: {
        opacity: 1,
        marginLeft: 10,
        transition: { duration: 0.4 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.4 }
    }
}


export const payInfoVariant = {
    initial: {
        opacity: 0,
        padding: 0,
        margin: 0,
        width: '100%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        marginBottom: 80,
        transition: { duration: 0.7 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.7 }
    }
}

export const productEnter = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        marginTop: 1000,
        opacity: 1,
        margin: -100,
        width: '0%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        margin: 0,
        marginTop: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 120,
        paddingBottom: 100,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const searchEnter = {
    initial: {
        paddingLeft: -1000,
        paddingRight: 0,
        paddingTop: 1000,
        paddingBottom: 100,
        marginTop: 1000,
        opacity: 1,
        margin: -100,
        width: '0%'
    },
    animate: {
        opacity: 1,
        width: '100%',
        marginTop: 0,
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 30,
        paddingBottom: 100,
        transition: { duration: 3 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 3 }
    }
}

export const basketProduct = {
    initial: {
        position: 'fixed',
        left: 100,
        top: 300,
        opacity: 1,
    },
    animate: {
        position: 'fixed',
        left: 100,
        top: 1000,
        opacity: 1,
        transition: { duration: 0.2 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 }
    }
}

export const homeSliderVariant = {
    initial: {
        marginLeft: -1000,
        opacity: 0,
    },
    animate: {
        marginLeft: 0,
        opacity: 1,
        marginTop: 55,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const brandSliderVariant = {
    initial: {
        // marginTop: -1000,
        opacity: 0,
    },
    animate: {
        // marginTop: 0,
        opacity: 1,
        marginBottom: 50,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const vitrinCategoriesVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
    },
    animate: {
        marginTop: 0,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const profileVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
    },
    animate: {
        marginTop: 120,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const ordersVariant = {
    initial: {
        marginTop: 1000,
        width: '160%',
        marginLeft: '-30%',
        opacity: 0,
    },
    animate: {
        marginTop: 20,
        width: '100%',
        marginLeft: 0,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const addressVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0
    },
    animate: {
        marginTop: 30,
        opacity: 1,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const formVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
        width: '20%',
        marginLeft: '40%'
    },
    animate: {
        marginTop: 80,
        opacity: 1,
        width: '90%',
        marginLeft: '5%',
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const paymentsVariant = {
    initial: {
        marginTop: 1000,
        opacity: 0,
        width: '20%',
        marginLeft: '40%'
    },
    animate: {
        marginTop: 30,
        opacity: 1,
        width: '100%',
        marginLeft: 0,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}

export const loginKapakVariant = {
    initial: {
        opacity: 0,
        marginLeft: -1000
    },
    animate: {
        opacity: 1,
        marginLeft: 0,
        transition: { duration: 1 }
    },
    exit: {
        opacity: 0,
        transition: { duration: 1 }
    }
}
