import React, { useEffect, useState } from 'react'
import { formVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { Input, Form, Button, Modal } from 'reactstrap'
import { encode, expriyFormat } from '../../components/general'
import axios from 'axios'
import { sepetigetir, siparistamamlaozel, taksitleriGetirKartla } from '../../api/api'
import { useForm } from 'react-hook-form'
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const Pay = () => {
    const [number, SetNumber] = useState('')
    const [name, setName] = useState('')
    const [date, SetDate] = useState('')
    const [cvc, SetCvc] = useState('')
    const [basketLength, setBasketLength] = useState('0')
    const [selectedBank, setSelectedBank] = useState('0')
    const [selectedTaksit, setSelectedTaksit] = useState('0')
    const [price, setPrice] = useState('')
    const [priceK, setPriceK] = useState('')
    const [isTaksit, setIsTaksit] = useState(false)
    const [first, setFirst] = useState(false)
    const [second, setSecond] = useState(false)
    const [third, setThird] = useState(false)
    const [fourth, setFourth] = useState(false)
    const [priceNote, setPriceNote] = useState('')
    const [notModal, setNotModal] = useState(false)
    const [dataTaksit, setDataTaksit] = useState([])
    const [selectedTaksitId, setSelectedTaksitId] = useState('0')
    const [jokerVadaa, setJokerVada] = useState(false)
    const { handleSubmit } = useForm()

    const onChangeExp = (e) => {
        SetDate(e.target.value);
        if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setThird(true) } else { setThird(false) }
    };

    const getTaksit = () => {
        if (number.length < 20 && number.length > 15) {
            axios.get(`${taksitleriGetirKartla}${number.split(' ').join('')}`).then(res => {
                if (res.data.length !== 0) {
                    const newList = []
                    newList.push({ Taksit_Baslik: "Peşin", Taksit_Id: 2, Taksit_KrediKarti: 1, Taksit_Oran: 0, Taksit_Sayi: 0 })
                    for (let i = 0; i < res.data.length; i++) {
                        newList.push(res.data[i])
                    }
                    setDataTaksit(newList)
                }
            })
        }
    }

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })
    }

    const onSubmit = (values, e) => {
        const newPrice = `${price},${priceK === '' ? '00' : priceK}`

        axios.get(`${siparistamamlaozel}${window.btoa(localStorage.getItem('auth'))},${window.btoa(selectedBank)},${window.btoa(selectedTaksit)},${encode(name)},${isTaksit ? 'MA==' : 'MQ=='},${window.btoa(newPrice)},${encode(priceNote !== '' ? priceNote : 'deneme')},${jokerVadaa ? 'MQ==' : 'MA=='}`).then(res => {
            if (res.data[0].ozelOdemeId !== null && res.data[0].ozelOdemeId !== undefined) {
                const value = JSON.stringify(res.data[0].form)
                const skt = date.split('/')
                const mp = number.split(' ')
                let newHtml = ''
                // 4824 6502 9034 0908
                newHtml = value.replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`).replace("name='pan' value=''", `name='pan' value='${number.replace(/\s/g, '')}'`).replace("name='Ecom_Payment_Card_ExpDate_Month' value=''", `name='Ecom_Payment_Card_ExpDate_Month' value='${skt[0]}'`).replace("name='Ecom_Payment_Card_ExpDate_Year' value=''", `name='Ecom_Payment_Card_ExpDate_Year' value='${skt[1]}'`).replace("name='cv2' value=''", `name='cv2' value='${cvc}'`).replace("name='mp' value=''", `name='mp' value='${mp[3]}'`).replace("name='kartsahibi' value=''", `name='kartsahibi' value='${name}'`)

                const pageContentUrl = encode(JSON.parse(newHtml))

                localStorage.setItem('treeDUrl', pageContentUrl)

                window.location.href = `/treed`

            } else {
                setNotModal(true)
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='pay'>
            <Header title={'ÖDEME YAP'} basket={basketLength} />

            <motion.div className='odeme' {...formVariant}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        type='input'
                        placeholder='Kart Sahibi Ad Soyad'
                        className='mb-2'
                        onChange={e => {
                            setName(e.target.value)
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFirst(true) } else { setFirst(false) }
                        }} />
                    <Input
                        type="tel"
                        placeholder='Kart Numarası'
                        className='mb-2'
                        name="number"
                        value={number}
                        onBlur={() => {
                            getTaksit()
                        }}
                        onChange={e => {
                            if (e.target.value.length < 20) {
                                e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                SetNumber(e.target.value)
                            }
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setSecond(true) } else { setSecond(false) }
                        }}
                    />
                    <Input
                        type="tel"
                        name="month"
                        placeholder="Ay/Yıl"
                        maxLength={5}
                        onChange={onChangeExp}
                        className='mb-2'
                        value={expriyFormat(date)}
                    />
                    <Input
                        placeholder='CVC'
                        type="tel"
                        name="cvc"
                        value={cvc}
                        className='mb-2'
                        onChange={e => {
                            if (e.target.value.length < 4) {
                                SetCvc(e.target.value)
                            } else {
                                SetCvc(e.target.value.slice(0, 3))
                            }
                            if (e.target.value !== '' && e.target.value !== null && e.target.value !== undefined) { setFourth(true) } else { setFourth(false) }
                        }}
                    />
                    <Input type='textarea' style={{ fontSize: 13 }} placeholder='Ödeme Notu' onChange={e => setPriceNote(e.target.value)} />
                    <div className='odeme-tutar'>
                        <div><span style={{ fontSize: 15 }}>Ödeme Tutarı</span></div>
                        <div className='ana'><Input type='number' onChange={e => setPrice(e.target.value)} /></div>
                        <div><span>,</span></div>
                        <div className='kusur'><Input type='number' className='pr-0' defaultValue={'00'} onChange={e => setPriceK(e.target.value)} /></div>
                        <div><small style={{ fontSize: 10, color: 'grey' }}>₺ - Örn: (100,00)</small></div>
                    </div>

                    <div className='taksitler'>
                        {
                            dataTaksit.map((item, index) => {
                                return (
                                    <div key={index} className={item.Taksit_Id !== selectedTaksitId ? 'item' : 'selectedItem shadow'} onClick={() => {
                                        setSelectedTaksitId(item.Taksit_Id);
                                        setSelectedTaksit(item.Taksit_Id);
                                        setSelectedBank(item.Taksit_KrediKarti)
                                        if (item.Taksit_Sayi === 0) {
                                            setIsTaksit(false)
                                        } else {
                                            setIsTaksit(true)
                                        }
                                    }} htmlFor="taksit">
                                        <div htmlFor="taksit">
                                            <input type='radio' id='taksit' name='taksit' className='mr-2 mt-2' />
                                            <small className='mb-1' htmlFor="taksit">{item.Taksit_Baslik}</small>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className='ml-4' htmlFor="joker">
                        <Input type='checkbox' id='joker' name='joker' onChange={() => setJokerVada(!jokerVadaa)} checked={jokerVadaa} />
                        <small htmlFor="joker">Joker Vadaa</small>
                    </div>

                    <div className='odeme-tamamla'>
                        <Button disabled={first === true && second === true && third === true && fourth === true ? false : true}
                            components={animatedComponents}>ÖDEMEYİ TAMAMLA</Button>
                    </div>
                </Form>
            </motion.div>

            <Modal isOpen={notModal} toggle={() => setNotModal(!notModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><h5>Asil Grup</h5></div>
                    <div><small>Ödeme işleminde sorun oluştu lütfen tekrar deneyiniz.</small></div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Pay