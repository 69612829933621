import React, { useEffect, useState } from 'react'
import { mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { ChevronLeft } from 'react-feather'
import axios from 'axios'
import { sayfalar } from '../../api/api'
import { useParams } from 'react-router-dom'

const Contracts = () => {
    const [data, setData] = useState([])
    const { id } = useParams()

    const getData = () => {
        axios.get(sayfalar).then(res => {
            if (res.status === 200) {
                const value = res.data
                for (let i = 0; i < value.length; i++) {
                    if (parseInt(value[i].Sayfa_Id) === parseInt(id)) {
                        setData(value[i])
                    }
                }
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='contracts'>
            <div className='header align-items-center'>
                <div className='text-secondary ml-2' onClick={() => window.history.back()}>
                    <ChevronLeft size={30} color='grey' />
                </div>
                <div className='text-secondary align-items-center'>
                    <span>{data.Sayfa_Baslik}</span>
                </div>
                <div></div>
            </div>

            <div className='content' dangerouslySetInnerHTML={{ __html: data.Sayfa_Aciklama }}></div>
        </motion.div>
    )
}

export default Contracts