import React, { useEffect, useState } from 'react'
import { homeSliderVariant, mobilVariant, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { sepeteekle, sepetigetir, urundetay } from '../../api/api'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, Input, Modal } from 'reactstrap'
import kargo from '../../img/kargo.png'
import 'swiper/css'

import { Minus, Plus } from 'react-feather'

const ProductDetail = () => {
    const { id } = useParams()
    const [basketLength, setBasketLength] = useState('0')
    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [langImg, setLangImg] = useState('')
    const [basketEnter, setBasketEnter] = useState(false)
    const [productLength, setProductLength] = useState(1)
    const [addLoad, setAddLoad] = useState(false)
    const [stokModal, setStokModal] = useState(false)

    const getData = () => {
        axios.get(`${urundetay}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                console.log(value)
                setData(value)
            }
        })

        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })
    }

    const addBasket = () => {
        setBasketEnter(true)
        axios.get(`${sepeteekle}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa(productLength)},${window.btoa('1')},${window.btoa('1')}`).then(res => {
            if (res.status === 200) {
                axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                    if (res.status === 200) {
                        const value = res.data
                        setBasketLength(`${value.length}`)
                    }
                })
            }
        })
    }

    useEffect(() => {
        getData()
        if (!basketEnter) {
            getData()
        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='product-detail'>
            <Header title={'ÜRÜN DETAYI'} basket={basketLength} />

            <motion.div {...homeSliderVariant} className='slider'>
                {data.Urun_Resimleri !== undefined ? data.Urun_Resimleri.length !== 0 ? <Swiper
                    className='w-100'
                    loop
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                >
                    {
                        data.Urun_Resimleri.map((item, index) => {
                            return (
                                <SwiperSlide key={index}><img src={item.kucukresim} onClick={() => { setLangImg(item.buyukresim); setModal(!modal) }} /></SwiperSlide>
                            )
                        })
                    }
                </Swiper> : null : null}
            </motion.div>

            <motion.div {...vitrinCategoriesVariant}>
                {data.Urun_Bilgileri !== undefined ? <div className='text-center mt-3'>
                    <div><span>{data.Urun_Bilgileri[0].Urun_Baslik}</span></div>
                    <div className='mt-1 text-secondary'><small>Ürün Kodu: </small><small>{data.Urun_Bilgileri[0].Urun_Kodu}</small></div>
                    <div className='mt-3'><b>Fiyatı</b></div>
                    <div className=''><b>{data.Urun_Bilgileri[0].Urun_SatisFiyati} ₺</b></div>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='adet'>
                    <div><Minus color='red' onClick={() => {
                        if (productLength > 1) {
                            setProductLength(productLength - 1)
                        }
                    }} /></div>
                    <div><Input type='number' value={productLength} disabled /></div>
                    <div><Plus color='red' onClick={() => {
                        if (parseInt(data.Urun_Bilgileri[0].Urun_StokMiktari) > productLength) {
                            setProductLength(productLength + 1)
                        }
                    }} /></div>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='sepet-ekle'>
                    <Button onClick={() => {
                        if (parseInt(data.Urun_Bilgileri[0].Urun_StokMiktari) > 0) {
                            addBasket();
                            setAddLoad(true)
                            setTimeout(() => {
                                setAddLoad(false)
                            }, 1900)
                        } else {
                            setStokModal(true)
                            // setTimeout(() => {
                            //     setStokModal(false)
                            // }, 2000)
                        }
                    }}>SEPETE EKLE</Button>
                </div> : null}

                <div className={addLoad ? 'animationimg' : 'notimg'}>
                    <div className='arti1'><span>+1</span></div>
                </div>

                {data.Urun_Bilgileri !== undefined ? <div className='cargo'>
                    <img src={kargo} />
                    <span>{data.Urun_Bilgileri[0].Urun_TedarikSuresi}</span>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div className='properties mt-4'>
                    <div className='border border-secondary p-2'><span>ÜRÜN ÖZELLİKLERİ</span></div>
                </div> : null}

                {data.Urun_Bilgileri !== undefined ? <div dangerouslySetInnerHTML={{ __html: data.Urun_Bilgileri[0].Urun_Aciklama }}></div> : null}
            </motion.div>

            <Modal isOpen={modal} toggle={() => setModal(!modal)} className='modal-dialog-centered'>
                <div><img src={langImg} alt='' width='100%' /></div>
            </Modal>

            <Modal isOpen={stokModal} toggle={() => setStokModal(!stokModal)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><h5>Stok Yok!</h5></div>
                    <div><span>Ürün stoğu bulunmamaktadır.</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button color='danger' className='px-3 py-0' onClick={() => setStokModal(false)}>Tamam</Button>
                    </div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='px-3 py-0 border border-danger bg-white text-danger' onClick={() => window.history.back()}>Alışverişe Dön</Button>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default ProductDetail