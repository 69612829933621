import React, { useEffect, useState } from 'react'
import { homeSliderVariant, mobilVariant, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import axios from 'axios'
import { sepetigetir, slider, vitrin } from '../../api/api'
import { Row, Col } from 'reactstrap'
import BottomBar from '../../components/bottombar'

const HomePage = () => {
    const [sliders, setSlider] = useState([])
    const [vitrins, setVitrin] = useState([])
    const [basketLength, setBasketLength] = useState('0')
    // const [brands, setBrands] = useState([])

    const getData = () => {
        axios.get(`${slider}${localStorage.getItem('auth')}`).then(res => {
            if (res.status === 200) {
                const newList = []
                const value = res.data
                for (let i = 0; i < value.length; i++) {
                    newList.push(value[i].imageLink)
                }
                setSlider(newList)
            }
        })

        axios.get(vitrin).then(res => {
            if (res.status === 200) {
                setVitrin(res.data)
            }
        })

        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })

        // axios.get(markalarGetir).then(res => {
        //     if (res.status === 200) { }
        //     setBrands(res.data)
        // })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='homepage'>
            <Header title={''} basket={basketLength} />

            <motion.div className='slider' {...homeSliderVariant}>
                <Swiper
                    className='w-100'
                    modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: true,
                    }}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                >
                    {
                        sliders.map((item, index) => {
                            return (
                                <SwiperSlide key={index}><img src={item} /></SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </motion.div>

            {/* <motion.div className='brands' {...brandSliderVariant}>
                <Swiper
                    className='w-100'
                    modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                    navigation
                    slidesPerView={2}
                    spaceBetween={10}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                    }}>
                    {
                        brands.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className='brandItem' onClick={() => window.location.href = `/product/${item.Marka_Id}/1`}>
                                        <span dangerouslySetInnerHTML={{ __html: item.Marka_Baslik }}></span>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </motion.div> */}

            <motion.div className='vitrin'{...vitrinCategoriesVariant}>
                <Row>
                    {
                        vitrins.map((item, index) => {
                            return (
                                <Col sm={6} md={6} xl={6} key={index}>
                                    <div className='item shadow'>
                                        <a href={`/product/${item.UrunKategori_Id}/0`}>{item.UrunKategori_Baslik}</a>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </motion.div>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default HomePage