import React, { useEffect, useState } from 'react'
import { formVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { sepetigetir, uyeProfilBilgileriGuncelle, uyebilgileri } from '../../api/api'
import axios from 'axios'
import { Form, Input, Button, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form'

const Account = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [dangerOpen, setDangerOpen] = useState(false)
    const [danger, setDanger] = useState('')
    const [data, setData] = useState([])
    const { handleSubmit } = useForm()

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })

        axios.get(`${uyebilgileri}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setData(res.data[0])
            }
        })
    }

    const onSubmit = (values, e) => {
        const name = window.btoa(unescape(encodeURIComponent(e.target[0].value)))
        const surname = window.btoa(unescape(encodeURIComponent(e.target[1].value)))
        const date = window.btoa(unescape(encodeURIComponent(e.target[2].value)))
        const phone = window.btoa(unescape(encodeURIComponent(e.target[3].value)))
        const unvan = window.btoa(unescape(encodeURIComponent(e.target[4].value)))
        const vergiD = window.btoa(unescape(encodeURIComponent(e.target[5].value)))
        const vergiN = window.btoa(unescape(encodeURIComponent(e.target[6].value)))
        const pass1 = window.btoa(unescape(encodeURIComponent(e.target[7].value)))
        const pass2 = window.btoa(unescape(encodeURIComponent(e.target[8].value)))

        axios.get(`${uyeProfilBilgileriGuncelle},${window.btoa(localStorage.getItem('auth'))},${name},${surname},${phone},${date},${pass1},${pass2},${unvan},${vergiD},${vergiN}`).then(res => {
            if (res.status === 200) {
                if (res.data[0].durum === '0') {
                    setDangerOpen(!dangerOpen)
                    setDanger(res.data[0].sonuc)
                }
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='account'>
            <Header basket={basketLength} title={'HESABIM'} />

            <motion.div className='info' {...formVariant}>
                {/* <div><b>Hesap Bilgilerim</b></div> */}
                {/* <div className='mt-2 mb-4'><span>Kullanıcı Bilgilerim</span></div> */}

                <div className='forms mt-4'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <span>Ad</span>
                            <Input type='input' defaultValue={data.Musteri_Ad} />
                        </div>
                        <div>
                            <span>Soyad</span>
                            <Input type='input' defaultValue={data.Musteri_Soyad} />
                        </div>
                        <div>
                            <span>Doğum Tarihi</span>
                            <Input type='date' defaultValue={data.Musteri_DogumTarihi} />
                        </div>
                        <div>
                            <span>Cep Telefonu</span>
                            <Input type='tel' defaultValue={data.Musteri_Telefon} />
                        </div>
                        <div>
                            <span>Firma Ünvanı</span>
                            <Input type='input' defaultValue={data.Musteri_FirmaUnvani} />
                        </div>
                        <div>
                            <span>Vergi Dairesi</span>
                            <Input type='input' defaultValue={data.Musteri_VergiDairesi} />
                        </div>
                        <div>
                            <span>Vergi Numarası</span>
                            <Input type='tel' defaultValue={data.Musteri_VergiNumarasi} />
                        </div>
                        <div className='mt-4 mb-2'>
                            <span className='text-dark'><b>Şifre Güncelleme</b></span>
                        </div>
                        <div>
                            <span>Yeni Şifre</span>
                            <Input type='password' placeholder='*********' />
                        </div>
                        <div>
                            <span>Yeni Şifre(Tekrar)</span>
                            <Input type='password' placeholder='*********' />
                        </div>
                        <Button>GÜNCELLE</Button>
                    </Form>
                </div>
            </motion.div>

            <Modal isOpen={dangerOpen} toggle={() => setDangerOpen(!dangerOpen)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><span style={{ fontSize: 13 }}>{danger}</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='bg-danger border-0' style={{ fontSize: 13, borderRadius: 15 }} onClick={() => setDangerOpen(!dangerOpen)}>
                            Tamam</Button>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Account