import React, { useEffect, useState } from 'react'
import { addressVariant, mobilVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { sayfalar, sepetigetir } from '../../api/api'
import axios from 'axios'

const About = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })

        axios.get(`${sayfalar}`).then(res => {
            if (res.status === 200) {
                if (res.data !== undefined && res.data !== null) {
                    setData(res.data[0])
                }
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='about'>
            <Header title={'ASİL HAKKINDA'} basket={basketLength} />

            <motion.div {...addressVariant}>
                <div className='content' dangerouslySetInnerHTML={{ __html: data !== undefined && data !== null ? data.Sayfa_Aciklama : null }}></div>
            </motion.div>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default About