import React, { useEffect, useState } from 'react'
import { mobilVariant, searchEnter, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import { Input, Button, Modal } from 'reactstrap'
import searchimg from '../../img/search.png'
import loadimg from '../../img/loading.svg'
import axios from 'axios'
import { aramayap, sepeteekle, sepetigetir } from '../../api/api'

const Search = () => {
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState([])
    const [basketLength, setBasketLength] = useState('0')
    const [loadProduct, setLoadProduct] = useState(false)
    const [loading, setLoading] = useState(false)
    const [basketEnter, setBasketEnter] = useState(false)
    const [selectedId, setSelectedId] = useState(0)
    const [stokModal, setStokModal] = useState(false)

    const getSearch = () => {
        axios.get(`${aramayap}${window.btoa(unescape(encodeURIComponent(searchValue)))},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setData(value)
                setLoading(true)
            }
        })
    }

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                const value = res.data
                setBasketLength(`${value.length}`)
            }
        })
    }

    const addBasket = (id) => {
        setBasketEnter(true)
        axios.get(`${sepeteekle}${window.btoa(id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa('1')},${window.btoa('1')},${window.btoa('1')}`).then(res => {
            if (res.status === 200) {
                axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
                    if (res.status === 200) {
                        const value = res.data
                        setBasketLength(`${value.length}`)
                    }
                })
            }
        })
    }

    useEffect(() => {
        getData()
        setTimeout(() => {
            if (!loadProduct) {
                setLoadProduct(true)
            }
        }, 1400);
        if (!basketEnter) {
            getData()
        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='search'>
            <Header title={'ARAMA YAP'} basket={basketLength} />

            <div className='searchfield'>
                <div className='inputs'><Input type='input' placeholder='Aranılacak kelime...' className='w-100' onChange={e => setSearchValue(e.target.value)} /></div>
                <div className='imgs' onClick={() => getSearch()}><span className='text-secondary'>Ara</span></div>
            </div>

            <motion.div {...vitrinCategoriesVariant}>
                <motion.div {...searchEnter} className='urunlers'>
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className='ml-2 mr-2 d-flex justify-content-center mb-4 bg-white roundend p-1 align-items-end text-center'>
                                    {loadProduct ? <div className='border-0 pb-3 m-1'>
                                        <div className='mb-3' onClick={() => window.location.href = `/product-detail/${item.Urun_Id}`}>
                                            <div><img src={item.Resim} alt='' /></div>
                                            <div style={{ height: 70 }}><span>{item.Urun_Baslik}</span></div>
                                            <div><small>Ürün Kodu: </small><small>{item.Urun_TicariYazilimId}</small></div>
                                            <div><span>Fiyatı</span></div>
                                            <div><span>{item.Etiket} ₺</span></div>
                                        </div>
                                        <div className='mt-2'>
                                            <Button onClick={() => {
                                                if (item.Stok > 0) {
                                                    addBasket(item.Urun_Id);
                                                    setSelectedId(item.Urun_Id)
                                                    setTimeout(() => {
                                                        setSelectedId(0)
                                                    }, 1900)
                                                } else {
                                                    setStokModal(true)
                                                }
                                            }}>Sepete Ekle</Button>
                                            <div className={item.Urun_Id === selectedId ? 'animationimg' : 'notimg'}>
                                                <div className='arti1'><span>+1</span></div>
                                            </div>
                                        </div>
                                    </div> : <div className='initProduct'>
                                        <img src={loadimg} alt='' />
                                    </div>}
                                </div>
                            )
                        })
                    }
                </motion.div>
            </motion.div>

            <Modal isOpen={stokModal} toggle={() => setStokModal(!stokModal)} className='modal-dialog-centered'>
                <div className='p-2'>
                    <div><h5>Stok Yok!</h5></div>
                    <div><span>Ürün stoğu bulunmamaktadır.</span></div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button color='danger' className='px-3 py-0' onClick={() => setStokModal(false)}>Tamam</Button>
                    </div>
                    <div className='mt-2 d-flex justify-content-center'>
                        <Button className='px-3 py-0 border border-danger bg-white text-danger' onClick={() => window.location.href = '/'}>Ana Sayfa</Button>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Search