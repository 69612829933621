import React, { useEffect, useState } from 'react'
import { mobilVariant, ordersVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { Input } from 'reactstrap'
import print from '../../img/print.png'
import { sepetigetir, siparisgecmisi } from '../../api/api'
import { ChevronRight } from 'react-feather'

const Orders = () => {
    const [basketLength, setBasketLength] = useState('0')
    const [initDate, setInitDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [data, setData] = useState([])

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                setBasketLength(`${res.data.length}`)
            }
        })

        axios.get(`${siparisgecmisi}${window.btoa(localStorage.getItem('auth'))},${initDate},${endDate}`).then(res => {
            if (res.status === 200) {
                console.log(res.data)
                setData(res.data)
            }
        })
    }

    useEffect(() => { getData() }, [])

    return (
        <motion.div {...mobilVariant} className='orders'>
            <Header basket={basketLength} title={'SİPARİŞLERİM'} />

            <div className='date-print'>
                <div><Input type='date' placeholder='Başlangıç Tarihi' onChange={e => setInitDate(e.target.value)} /></div>
                <div><Input type='date' placeholder='Bitiş Tarihi' onChange={e => setEndDate(e.target.value)} /></div>
                <div><img src={print} alt='' /></div>
            </div>

            <div className='headers'>
                <div><span>SİPARİŞ NO</span></div>
                <div><span>SİPARİŞ TARİHİ</span></div>
                <div><span>TOPLAM TUTAR</span></div>
            </div>

            <motion.div className='order-table'{...ordersVariant}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} className='item' onClick={() => window.location.href = `/order-detail/${item.Siparis_Id}`}>
                                <div><b>{item.Siparis_No}</b></div>
                                <div><small>{item.Siparis_Tarih}</small></div>
                                <div><small>{item.Siparis_SepetToplamTutari} ₺</small></div>
                                <div><ChevronRight size={20} color='grey' /></div>
                            </div>
                        )
                    })
                }
            </motion.div>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Orders