import React, { useState } from 'react'
import { loginKapakVariant, mobilVariant, vitrinCategoriesVariant } from '../../components/animation'
import '../../css/style.css'
import { motion } from 'framer-motion'
import headerImg from '../../img/image.png'
import logo from '../../img/logo.png'
import { Input, Form, Button, Modal } from 'reactstrap'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import { kayitol } from '../../api/api'

const Register = () => {
    const [modal, setModal] = useState(false)
    const [sozlesmeOnay, setSozlesmeOnay] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [registerMessage, setRegisterMessage] = useState('')
    const [message, setMessage] = useState('')
    const { handleSubmit } = useForm()

    const onSubmit = (values, e) => {
        const name = window.btoa(e.target[0].value)
        const surname = window.btoa(e.target[1].value)
        const phone = window.btoa(e.target[2].value)
        const mail = window.btoa(e.target[3].value)
        const password = window.btoa(e.target[4].value)
        const firmaUnvan = window.btoa(unescape(encodeURIComponent(e.target[5].value)))
        const vergiDairesi = window.btoa(e.target[6].value)
        const vergiNumarasi = window.btoa(e.target[7].value)
        const onay = e.target[8].checked

        if (onay) {
            axios.get(`${kayitol}${name},${surname},${phone},${mail},${password},${decodeURIComponent(escape(firmaUnvan))},${vergiDairesi},${vergiNumarasi}`).then(res => {
                if (res.status === 200) {
                    const value = res.data[0]
                    if (value.durum !== '1') {
                        setIsRegister(true)
                        setModal(true)
                        setRegisterMessage(value.sonuc)
                        setTimeout(() => {
                            window.location.href = '/login'
                        }, 3500);
                    } else {
                        setIsRegister(true)
                        setModal(true)
                        setRegisterMessage(value.sonuc)
                        setTimeout(() => {
                            window.location.href = '/login'
                        }, 3500);
                    }
                }
            })
        } else {
            console.log('modal')
            setSozlesmeOnay(true)
            setModal(true)
        }
    }

    return (
        <motion.div {...mobilVariant} className='register'>
            <motion.div {...loginKapakVariant} className='header-img'>
                <img src={headerImg} alt='' />
            </motion.div>

            <motion.div {...vitrinCategoriesVariant}>
                <div className='logo'>
                    <img src={logo} alt='' />
                </div>

                <div className='login-text'>
                    <span>Asil Otogaz Bayi Kayıt</span>
                </div>

                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='login-form'>
                        <div className='mt-2'>
                            <Input type='input' placeholder='Ad' />
                        </div>
                        <div className='mt-2'>
                            <Input type='input' placeholder='Soyad' />
                        </div>
                        <div className='mt-2'>
                            <Input type='tel' placeholder='Tel' />
                        </div>
                        <div className='mt-2'>
                            <Input type='input' placeholder='Email Adresi' />
                        </div>
                        <div className='mt-2'>
                            <div>
                                <div><Input type='password' placeholder='Şifreniz' /></div>
                                <div></div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Input type='input' placeholder='Firma Ünvanı' />
                        </div>
                        <div className='mt-2'>
                            <Input type='input' placeholder='Vergi Dairesi' />
                        </div>
                        <div className='mt-2'>
                            <Input type='number' placeholder='Vergi Numarası' />
                        </div>
                        <div className='mt-2 pl-4 pr-4'>
                            <Input type='checkbox' />
                            <span className='text-secondary'><b className='text-dark' onClick={() => window.location.href = '/contracts/55'}>Üyelik Sözleşmesi</b>'ni okudum ve kabul ediyorum.</span>
                        </div>
                    </div>
                    <div className='align-items-center text-center'>
                        <Button className='login-btn'>ÜYE OLUN</Button>
                        <div className='yada mt-2 text-secondary'><span>ya da</span></div>
                        <Button className='register-btn' onClick={() => window.location.href = '/login'}>GİRİŞ YAPIN</Button>
                    </div>
                </Form>

                <div className='texts text-secondary mb-5'>
                    <div><a href='/contracts/55'>Üyelik Sözleşmesi</a></div>
                    <div><span>|</span></div>
                    <div><a href='/contracts/8'>Gizlilik Taahhütü</a></div>
                </div>
            </motion.div>

            <Modal isOpen={modal} toggle={() => { setModal(!modal); setMessage('') }} className='modal-dialog-centered'>
                {
                    isRegister ? <div className='p-4'>
                        <div className='text-center'><h5>Asil Otogaz</h5></div>
                        <div className='mt-3'><span>{registerMessage}</span></div>
                        <div className='mt-3 d-flex justify-content-center'><Button className='iptal border border-primary bg-white text-primary mt-2' onClick={() => { setModal(false); setIsRegister(false) }}>Tamam</Button></div>
                    </div> : null
                }

                {
                    sozlesmeOnay ? <div className='p-4'>
                        <div className='text-center'><h5>Asil Otogaz</h5></div>
                        <div className='mt-3'><span>Lütfen Üyelik Sözleşmesi'ni okuyup onaylayınız!</span></div>
                        <div className='mt-3 d-flex justify-content-center'><Button className='iptal border border-primary bg-white text-primary mt-2' onClick={() => { setModal(false); setSozlesmeOnay(false) }}>Tamam</Button></div>
                    </div> : null
                }

                {
                    sozlesmeOnay && isRegister ? <div>
                        <div className='header'>
                            <div>Hatalı Giriş</div>
                            <div className='close' onClick={() => setModal(!modal)}>x</div>
                        </div>
                        <div className='message'>{message}</div>
                        <div className='d-flex justify-content-end mb-2 mr-2' onClick={() => setModal(!modal)}><div className='ok'>Tamam</div></div>
                    </div> : null
                }
            </Modal>
        </motion.div>
    )
}

export default Register