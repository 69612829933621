import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import '../../css/style.css'
import { formVariant, mobilVariant, nullBasketVariant } from '../../components/animation'
import Header from '../../components/header'
import BottomBar from '../../components/bottombar'
import axios from 'axios'
import { sepeteekle, sepetiBosalt, sepetigetir, sepettensil } from '../../api/api'
import { Card, Input, Button, Modal } from 'reactstrap'
import { paraFormati } from '../../components/general'
import loadimg from '../../img/loading.svg'
import { ChevronLeft, Meh } from 'react-feather'
import { useParams } from 'react-router-dom'

const Basket = () => {
    const [data, setData] = useState([])
    const [price, setPrice] = useState(0)
    const [basketLength, setBasketLength] = useState('0')
    const [selectedId, setSelectedId] = useState(0)
    const [deleteModal, setDeleteModal] = useState(false)
    const [nullBasket, setNullBasket] = useState(false)
    const [loadProduct, setLoadProduct] = useState(false)
    const [message, setMessage] = useState("")
    const { id } = useParams()

    const getData = () => {
        axios.get(`${sepetigetir}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                let pricetotal = 0
                const value = res.data
                setData(value)
                setBasketLength(`${value.length}`)
                for (let i = 0; i < value.length; i++) {
                    const newValue = value[i].Etiket * value[i].Sepet_Miktar
                    pricetotal += newValue
                }
                setPrice(pricetotal)
            }
        })
    }

    const deleteProduct = () => {
        axios.get(`${sepettensil}${window.btoa(selectedId)},${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                console.log(res.data)
                getData()
                setDeleteModal(false)
                setNullBasket(false)
            }
        })
    }

    const nullBasketData = () => {
        axios.get(`${sepetiBosalt}${window.btoa(localStorage.getItem('auth'))}`).then(res => {
            if (res.status === 200) {
                getData()
                setDeleteModal(false)
                setNullBasket(false)
            }
        })
    }

    useEffect(() => {
        getData()
        setTimeout(() => {
            if (!loadProduct) {
                setLoadProduct(true)
            }
        }, 1400);

        if (id === "1") {

        }
    }, [])

    return (
        <motion.div {...mobilVariant} className='basket'>
            <Header title={'SEPETİM'} basket={basketLength} />

            {data.length !== 0 ? <motion.div {...formVariant} className='my-basket'>
                {
                    data.map((item, index) => {
                        return (
                            loadProduct ? <div key={index} className='mb-2 pt-2 pb-2 bg-white rounded item'>
                                <div className='d-flex justify-content-start align-items-center'>
                                    <div><img src={item.Medya} alt='' /></div>
                                    <div style={{ maxWidth: 190 }}>
                                        <div><span>{item.Urun_Baslik}</span></div>
                                        <div><small>Ürün Kodu: {item.Urun_TicariYazilimId}</small></div>
                                        <div><span><b>Toplam</b></span></div>
                                        <div><span><b>{item.Etiket} ₺</b></span></div>
                                    </div>
                                    <div className='ml-3'>
                                        <div><small>ADET</small></div>
                                        <div><Input type='number' min={0} defaultValue={item.Sepet_Miktar} onChange={e => {
                                            if (parseInt(e.target.value) < 1) {

                                            } else {
                                                if (e.target.value.search('-') < 0) {
                                                    if (parseInt(e.target.value) <= parseInt(item.StokMiktar)) {
                                                        axios.get(`${sepeteekle}${window.btoa(item.Urun_Id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa(parseInt(e.target.value) !== parseInt(item.Sepet_Miktar) ? parseInt(e.target.value) - parseInt(item.Sepet_Miktar) : e.target.value)},${window.btoa('1')},${window.btoa('1')}`).then(res => {
                                                            if (res.status === 200) { getData() }
                                                            if (res.data[0].durum === "0") {
                                                                setMessage(res.data[0].sonuc)
                                                            }
                                                        })
                                                    }
                                                } else {
                                                    axios.get(`${sepeteekle}${window.btoa(item.Urun_Id)},${window.btoa(localStorage.getItem('auth'))},${window.btoa('0')},${window.btoa('1')},${window.btoa('1')}`).then(res => {
                                                        if (res.status === 200) { getData() }
                                                        if (res.data[0].durum === "0") {
                                                            setMessage(res.data[0].sonuc)
                                                        }
                                                    })
                                                }
                                            }
                                        }} /></div>
                                    </div>
                                    <div className='align-items-center text-center ml-3' onClick={() => { setSelectedId(item.Urun_Id); setDeleteModal(!deleteModal) }}>
                                        <span style={{ fontSize: 25 }}>x</span>
                                    </div>
                                </div>
                            </div> : <div key={index} className='initProduct'>
                                <img src={loadimg} alt='' />
                            </div>
                        )
                    })
                }
            </motion.div> : <motion.div {...nullBasketVariant} className='nullbasket' style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <div>
                    <div className='text-center border border-secondary rounded text-secondary p-3'>
                        <div><Meh size={30} /></div>
                        <div className='mt-2'><span>Sepetinizde Ürün Bulunamadı</span></div>
                    </div>
                    <Button className='bg-white border border-secondary text-secondary mt-3' onClick={() => window.location.href = '/'}><ChevronLeft size={18} className='mr-2' /><small>Ana Sayfa</small></Button>
                </div>
            </motion.div>}

            {data.length !== 0 ? <div className='tamamla'>
                <Card className='p-2 text-center border-0'><div><b><span>Toplam: </span><span>{paraFormati(price)} ₺</span></b></div></Card>
                <div className='w-100 d-flex mt-2'>
                    <div className='w-50'>
                        <Button className='bosalt' onClick={() => setNullBasket(!nullBasket)}>SEPETİ BOŞALT</Button>
                    </div>
                    <div className='w-50'>
                        <Button className='satin' onClick={() => window.location.href = '/address-info'}>SATIN AL</Button>
                    </div>
                </div>
            </div> : null}

            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>Ürün Sil</b></div>
                    <div><small>Sepetten ürünü silmek istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-end mt-2'>
                        <div><Button className='border border-primary bg-white text-primary' style={{ borderRadius: 15, fontSize: 13 }} onClick={() => setDeleteModal(!deleteModal)}>Hayır</Button></div>
                        <div className='ml-2'><Button className='bg-primary border-0' onClick={() => { setDeleteModal(!deleteModal); deleteProduct() }} style={{ borderRadius: 15, fontSize: 13 }}>Evet</Button></div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={nullBasket} toggle={() => setNullBasket(!nullBasket)} className='modal-dialog-centered'>
                <div className='p-3'>
                    <div><b>Sepeti Boşalt</b></div>
                    <div><small>Sepeti boşaltmak istediğinize emin misiniz?</small></div>
                    <div className='d-flex justify-content-end mt-2'>
                        <div><Button className='border border-primary bg-white text-primary' style={{ borderRadius: 15, fontSize: 13 }} onClick={() => setNullBasket(!nullBasket)}>Hayır</Button></div>
                        <div className='ml-2'><Button className='bg-primary border-0' onClick={() => nullBasketData()} style={{ borderRadius: 15, fontSize: 13 }}>Evet</Button></div>
                    </div>
                </div>
            </Modal>

            <BottomBar basket={basketLength} />
        </motion.div>
    )
}

export default Basket